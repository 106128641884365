export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["哈嘍 i18n !!"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期開獎"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下期開獎時間: "])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期號"])},
  "reward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開獎號碼"])},
  "singleDecision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結果"])},
  "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇查詢日期"])},
  "result1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["單"])},
  "result2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小單"])},
  "result3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
  "result4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小雙"])},
  "result5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["雙"])},
  "openTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
  "rewardLen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已開", _interpolate(_named("existing")), "期，剩餘", _interpolate(_named("remain")), "期"])},
  "rewardRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開獎紀錄"])},
  "choseDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇日期"])},
  "game1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賓果開獎動畫"])},
  "game2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搖彩機"])},
  "game3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開彩球"])},
  "specialNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特別號"])},
  "sumArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賓果各區大小加總值"])},
  "sumSortArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["賓果各區順序加總值"])},
  "lotteryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台灣5分賓果"])},
  "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停開中..."])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開獎中..."])},
  "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開獎音效"])},
  "sizeSort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大小顺序"])},
  "openSort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開獎顺序"])},
  "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台灣五分彩"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台灣PK10"])},
  "advertisement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["廣告區塊"])},
  "lotteryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本站易記域名: 台灣賓果.com"])}
}