export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["哈嘍 i18n !!"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期开奖"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下期开奖时间: "])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期号"])},
  "reward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开奖号码"])},
  "singleDecision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结果"])},
  "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择查询日期"])},
  "result1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单"])},
  "result2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小单"])},
  "result3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
  "result4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小双"])},
  "result5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["双"])},
  "openTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间"])},
  "rewardLen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["已开", _interpolate(_named("existing")), "期，剩余", _interpolate(_named("remain")), "期"])},
  "rewardRecord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开奖纪录"])},
  "choseDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择日期"])},
  "game1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宾果开奖动画"])},
  "game2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摇彩机"])},
  "game3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开彩球"])},
  "specialNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特别号"])},
  "sumArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宾果各区大小加总值"])},
  "sumSortArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宾果各区顺序加总值"])},
  "lotteryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾5分宾果"])},
  "stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["停开中..."])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开奖中..."])},
  "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开奖音效"])},
  "sizeSort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大小顺序"])},
  "openSort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开奖顺序"])},
  "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾五分彩"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["台湾PK10"])},
  "advertisement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广告区块"])},
  "lotteryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本站易记域名: 台湾宾果.com"])}
}